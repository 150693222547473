<template>
  <div class="search-container">
    <a-form
      :disabled="getOrgDetails && !getOrgDetails.accountSetup && !isSimpatraAdmin"
      class="search-form"
      @submit.native.prevent
      @keyup.enter.native="navigateToQueryResults"
    >
      <a-input v-model="query" size="medium" :placeholder="`Search ${placeholderText}`" :maxlength="50">
        <a-select slot="prepend" v-model="categorySlug" class="category-prepend">
          <a-select-option value="all-products">All</a-select-option>
          <a-select-option value="pharmaceuticals">Pharmaceuticals</a-select-option>
          <a-select-option value="medical-supplies">Medical Supplies</a-select-option>
          <a-select-option value="pharmacy">Pharmacy</a-select-option>
          <a-select-option value="nutraceuticals">Nutraceuticals</a-select-option>
          <a-select-option value="medical-equipment">Medical Equipment</a-select-option>
        </a-select>
        <a-button slot="append" class="button-append" @click="navigateToQueryResults">
          <font-awesome-icon :icon="['fas', 'search']" />
        </a-button>
      </a-input>
    </a-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import userMe from "@/services/user-me";

export default {
  name: "Searchbar",
  computed: {
    ...mapGetters({
      searchQuery: "marketplaceSearch/getQuery",
      searchCategory: "marketplaceSearch/getCategory",
      getCurrentUser: "getCurrentUser",
    }),
    ...mapGetters("accountsModule", ["getOrgDetails"]),
    query: {
      get() {
        return this.searchQuery;
      },
      set(query) {
        this.commitQuery(query);
      },
    },
    categorySlug: {
      get() {
        return this.searchCategory;
      },
      set(categorySlug) {
        this.commitCategory(categorySlug);
      },
    },
    placeholderText() {
      return this.categorySlug.split("-").join(" ");
    },
    getMyRoles() {
      return userMe.getMyRoles();
    },
    isSimpatraAdmin() {
      return this.getMyRoles.isSimpatraAdmin;
    },
  },
  methods: {
    ...mapActions({
      commitQuery: "marketplaceSearch/commitSetQuery",
      commitCategory: "marketplaceSearch/commitSetCategory",
    }),
    async navigateToQueryResults() {
      this.$router.push({ name: "SearchList", query: { q: this.query, c: this.categorySlug } });
    },
  },
};
</script>

<style lang="scss">
.search-container {
  display: flex;
  flex: 1;
  justify-content: center;

  .search-form {
    width: 100%;
  }
  .category-prepend {
    width: 75px;
  }
}

@media screen and (max-width: 425px) {
  .search-container {

    .category-prepend {
      width: 48px;
    }
  }
}
</style>
